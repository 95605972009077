import React, { useState, useEffect } from 'react';
import SingleColor from './SingleColor';

import Values from 'values.js';

function App() {
  const [color, setColor] = useState();
  const [isError, setIsError] = useState(false);
  const [list, setList] = useState(new Values('#f15025').all(10));

  const onSubmitEvent = (e) => {
    e.preventDefault();
    try {
      var colors = new Values(color).all(10);
      setList(colors);
      setIsError(false);
    } catch (error) {
      console.log(error);
      setIsError(true);
    }
  };
  return (
    <>
      <section className="container">
        <h3>Color Generation</h3>
        <form onSubmit={(e) => onSubmitEvent(e)}>
          <input
            type="text"
            value={color}
            placeholder="#f15025"
            className={`${isError ? 'error' : null}`}
            onChange={(e) => setColor(e.target.value)}
          />
          <button className="btn" type="submi">
            Submit
          </button>
        </form>
      </section>
      <section className="colors">
        {list.map((color, index) => {
          return <SingleColor key={index} color={color} index={index} />;
        })}
      </section>
    </>
  );
}

export default App;
